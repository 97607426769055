import React, { useState, useEffect } from "react";
import "./index.css";
import axios from "axios";

const OpenPacket = (props) => {
    const [redemption_data, setRedemptionData] = useState(props.popup_content);
    const [deposit, setDeposit] = useState();
    const { popup_activity } = props;

    const redeemPackets = async (type) => {
        let data = {};
        let params = {};
        let updated_params = {};

        if (type === 4) {
            params = {
                params: {
                    uuid: sessionStorage.getItem("uuid"),
                    token: sessionStorage.getItem('token'),
                    type: type,
                    timeLimitActivityType: checkTimeLimit(),
                },
            };
            updated_params = {
                params: {
                    uuid: sessionStorage.getItem("uuid"),
                    type: type,
                    timeLimitActivityType: checkTimeLimit(),
                },
            };
        } else {
            params = {
                params: {
                    uuid: sessionStorage.getItem("uuid"),
                    token: sessionStorage.getItem('token'),
                    type: type,
                },
            };
            updated_params = {
                params: {
                    uuid: sessionStorage.getItem("uuid"),
                    type: type,
                },
            };
        }

        data.type = type;
        const getRedPackets = await axios.get(
            "/api/v1/redpacket/getRedPackets",
            params
        );
        if (getRedPackets && getRedPackets.data.data.code !== 0) {
            openPopup("info", getRedPackets.data.data.code, type);
            return null;
        } else {
            data.redeemed_packets = getRedPackets.data.data.data.redPackets;
        }
        if (getRedPackets && (!getRedPackets.data.data.userAuthenticated)) {
            sessionStorage.clear();
        }

        const getNotReceivingRedPackets = await axios.get(
            "/api/v1/redpacket/getNotReceivingRedPackets",
            updated_params
        );
        if (getNotReceivingRedPackets && getNotReceivingRedPackets.data.data.code !== 0) {
            openPopup("info", getNotReceivingRedPackets.data.data.code, type);
            return null;
        } else {
            data.available_packets = getNotReceivingRedPackets.data.data.data.notReceivingRedPacketsAmount;
        }

        setRedemptionData((prevState => {
            prevState = data;
            return ({...prevState});
        }));

        return data;
    };

    const checkTimeLimit = () => {
        const spring_festival_start = Date.parse("12 Feb 2021 00:00:00 GMT");
        const spring_festival_end = Date.parse("12 Feb 2021 23:59:59 GMT")
        const yuanxiao_festival_start = Date.parse("26 Feb 2021 21:00:00 GMT");
        const yuanxiao_festival_end = Date.parse("26 Feb 2021 21:30:00 GMT");

        let limit = "";
        const now = new Date();

        if (spring_festival_start <= now < spring_festival_end) {
            limit = "springFestivalActivities";
        } else if (yuanxiao_festival_start <= now < yuanxiao_festival_end) {
            limit = "yuanxiaoActivities";
        } else {
            limit = "springFestivalActivities";     // remove condition on live
        }

        return limit;
    }

    const redemptionLink = async (activity) => {
        const result = await redeemPackets(activity);
        if (result) {
            openPopup("open", result, activity);
        }
    };

    const openPopup = (type, content, activity) => {
        props.onTrigger(type, content, activity);
    };

    useEffect(() => {
        const getRedirectionLinks = async() => {
            const config = await axios.request({
                method: 'GET',
                url: '/api/config/interface/getConfig',
            })

            let deposit = config.data.data.crm_url + '/fund/deposit';


            setDeposit(deposit);
        }

        getRedirectionLinks();
    }, []);

    return (
        <div className="modal modal_open">
            <figure className="firework"><img src="https://cdn.dootech.io/doo-prime-cny/Gifs/Firework_Bacgkround.gif" /></figure>
            <div className="modal_content">
                <div className="modal_wrapper">
                    <span className="close" onClick={props.closePopup}>
                        &#10005;
                    </span>
                    <img src="https://cdn.dootech.io/doo-prime-cny/Gifs/open_angpau.gif"/>
                    {
                        (redemption_data.type === 1) &&
                            <div className="content_wrapper">
                                <p className="model_content_head open-title wow fadeInUp"> 恭喜您! </p>
                                <div className="open-content-wrapper">
                                    <label>
                                        <p className="model_content_body open-con wow fadeInUp">
                                            获得 <b>US$ {redemption_data.redeemed_packets}</b> 奖金红包
                                            <br/>
                                            奖金已入账, 可登录用户中心首页
                                            <br/>
                                            查看"我的钱包"。
                                        </p>
                                    </label>
                                    <p className="angpow-balance wow fadeInUp">
                                        待领取红包为 <b>{redemption_data.available_packets}</b> 个
                                    </p>
                                    {redemption_data.available_packets > 0 && (
                                        <button className="popupbutton wow fadeInUp" onClick={() => redemptionLink(redemption_data.type)}>继续领取</button>
                                    )}
                                    {redemption_data.available_packets === 0 && (
                                        <button className="popupbutton wow fadeInUp" onClick={() => openPopup("info", "insufficient", popup_activity)}>继续领取</button>
                                    )}
                                </div>
                            </div>
                    }
                    {
                        (redemption_data.type !== 1) &&
                            <div className="content_wrapper">
                                <p className="model_content_head wow fadeInUp"> 恭喜您! </p>
                                <div className="open-content-wrapper wow fadeInUp">
                                    <label>
                                        <p className="model_content_body open-con wow fadeInUp">
                                            获得 <b>US$ {redemption_data.redeemed_packets}</b> 奖金红包
                                            <br/>
                                            奖金已入账, 可登录用户中心首页
                                            <br/>
                                            查看"我的钱包"。
                                        </p>{" "}
                                        <p className="model_content_body2 open-con wow fadeInUp">Doo Prime 祝您交易大吉，财源滚滚！</p>
                                    </label>
                                </div>
                                <button className="popupbutton wow fadeInUp"><a href={deposit}>立即入金交易</a></button>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default OpenPacket;
