import React from 'react';
import "./index.css";

const Mask = () => {
    return (
        <div className="mask">
            <img id="mask_img" src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-tech/images/doo_tech_MaskTip.png" alt="tip" />
        </div>
    );
}

export default Mask;
