import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import PopUp from '../popup';
import Navbar from '../navbar';
import Header from './header';
import Content from '../content';
import Footer from './footer';
import QRCode from 'qrcode';
import LiveChat from 'react-livechat'

import './index.css';
import axios from "axios";
import 'animate.css';
import WOW from 'wowjs';

const countries = [
    "Afghanistan",
    "Austria",
    "Azerbaijan",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Myanmar",
    "Canada",
    "Congo",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Estonia",
    "Ethiopia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Guyana",
    "Haiti",
    "Hong Kong",
    "Hungary",
    "Ireland",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Israel",
    "Italy",
    "Japan",
    "Latvia",
    "Lebanon",
    "Lithuania",
    "Luxembourg",
    "Malaysia",
    "Mali",
    "Malta",
    "Netherlands",
    "North Korea",
    "Poland",
    "Portugal",
    "Romania",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Sri Lanka",
    "Somalia",
    "Spain",
    "Sudan",
    "Sweden",
    "Trinidad and Tobago",
    "Tunisia",
    "Venezuela, Bolivarian Republic of",
    "United States of America"
];

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_type: 2,
            country_code: null,
            get_account_details: '',
            get_red_packet_details: '',
            qr: '',
            popup: '',
            popup_type: '',
            popup_content: '',
            show_redeem_button: false
        };
    }

    async componentDidMount() {
        const currentUrl = window.location.href;
        let encryptedToken = this.getParamString('encryptedToken', currentUrl);

        // if values data is true
        if (encryptedToken && !this.checkLogin()) {

            console.log('the encryption token has passed')
            const uuid = await axios({
                url: '/api/v1/crm/getuuid',
                method: 'POST',
                data: {
                    encryptedToken: encryptedToken
                }
            })

            this.setState({ user_type: uuid.data.result.customerDetails.isCustomer, get_account_details: uuid.data.result.customerDetails });

            // clear popup flag for new session
            // localStorage.clear();

            // user is logged in
            sessionStorage.setItem('uuid', uuid.data.result.uuid)
            sessionStorage.setItem('token', uuid.data.result.token)

            // getRedPacketDetails api
            this.timer = setInterval(async ()=> {
                const verifyUser = await this.verifyUser()
                if (!verifyUser) sessionStorage.clear()
                const getRedPacket = await axios.get('/api/v1/redpacket/get',
                {
                    params: {
                        uuid: sessionStorage.getItem('uuid'),
                        token: sessionStorage.getItem('token')
                    }
                });

                if (!getRedPacket.data.data.userAuthenticated) sessionStorage.clear()

                this.setState({ user_type: getRedPacket.data.data.data.isCustomer, get_account_details: getRedPacket.data.data.data });

                const getRedPacketDetails = await axios.get('/api/v1/redpacket/getDetails',
                {
                    params: {
                        uuid: sessionStorage.getItem('uuid'),
                        token: sessionStorage.getItem('token')
                    }
                })
                this.setState({ get_red_packet_details: getRedPacketDetails.data.data.data });
            }, 3000);

            QRCode.toDataURL(window.location.href.split('?')[0] + '/share?uuid=' + uuid.data.result.uuid + '&token=' + sessionStorage.getItem('token'), {color: {dark: '#000000', light: '#FFFFFF'}, margin: 1})
                .then(url => {
                    this.setState({ qr: url });
                })
                .catch(err => {
                    console.error(err);
                })
        } else if (this.checkLogin()) {
            this.timer = setInterval(async ()=> {
                const verifyUser = await this.verifyUser()
                if (!verifyUser) sessionStorage.clear()

                const getRedPacket = await axios.get('/api/v1/redpacket/get',
                {
                    params: {
                        uuid: sessionStorage.getItem('uuid'),
                        token: sessionStorage.getItem('token')
                    }
                });
                if (!getRedPacket.data.data.userAuthenticated) sessionStorage.clear()
                this.setState({ user_type: getRedPacket.data.data.data.isCustomer, get_account_details: getRedPacket.data.data.data });

                const getRedPacketDetails = await axios.get('/api/v1/redpacket/getDetails',
                {
                    params: {
                        uuid: sessionStorage.getItem('uuid'),
                        token: sessionStorage.getItem('token')
                    }
                });

                this.setState({ get_red_packet_details: getRedPacketDetails.data.data.data });
            }, 3000);

            QRCode.toDataURL(window.location.href.split('?')[0] + '/share?uuid=' + sessionStorage.getItem('uuid') + '&token=' + sessionStorage.getItem('token'), {color: {dark: '#000000', light: '#FFFFFF'}, margin: 1})
                .then(url => {
                    this.setState({ qr: url });
                })
                .catch(err => {
                    console.error(err);
                })
        } else {
            const verifyUser = await this.verifyUser()
            if (!verifyUser) console.log('user is not authenticated')
            const getRedPacket = await axios.get('/api/v1/redpacket/get');
            this.setState({ user_type: 2, get_account_details: getRedPacket.data.data.data });
        }

        // location api
        const country = await axios({
            url: '/api/v1/location/',
            method: 'GET'
        });
        this.checkCountryCode(country.data.data);
    }

    getParamString = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    checkCountryCode = (data) => {
        if (countries.some(item => item === data.country_name)) {
            this.setState({ country_code: data.country_code });
        }
    }

    togglePopup = async() => {
        this.setState({ popup: !this.state.popup });
        document.documentElement.style.overflowY = "";
    }

    triggerPopup = async(type, content, activity) => {
        this.setState( { popup: true, popup_type: type, popup_content: content, popup_activity: activity });
    }

    checkLogin = () => {
        if (sessionStorage.getItem('token')) {
            return true
        }
        return false
    }

    verifyUser = async () => {
        if (sessionStorage.getItem('token')) {
            const postData = {
                method: 'POST',
                url: '/api/v1/user/sessionIntegrity',
                data: {
                    uuid: sessionStorage.getItem('uuid'),
                    token: sessionStorage.getItem('token')
                }
            }
            const res = await axios(postData)

            if (res.data.userAuthenticated) return true
        } else {
            return false
        }
    }

    userLogout = () => {
        // localStorage.clear();
        sessionStorage.clear();
        this.setState({ user_type: 2 });
    }


    is_weixin(){
        const ua = navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { user_type, qr, get_account_details, get_red_packet_details, country_code, popup, popup_type, popup_content, popup_activity } = this.state;

        return (
            <div className="layout">
                { popup && popup_content && (
                    <PopUp
                        user_type={user_type}
                        popup_type={popup_type}
                        popup_content={popup_content}
                        popup_activity = {popup_activity}
                        get_account_details={get_account_details}
                        closePopup={this.togglePopup.bind(this)}
                        onTrigger={this.triggerPopup.bind(this)}
                    />
                )
                }
                <Navbar onUserLogout={this.userLogout.bind(this)} />
                <Header />
                <Content
                    qr={qr}
                    user_type={user_type}
                    get_account_details={get_account_details}
                    get_red_packet_details={get_red_packet_details}
                    onTrigger={this.triggerPopup.bind(this)}
                />
                <Footer country_code={country_code} />
                {
                    !this.is_weixin() &&
                    <LiveChat license={10956587} group={1} chatBetweenGroups={false}/>
                }
            </div>
        );
    }
}

export default withRouter(Layout);
