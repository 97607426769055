import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import QRCode from 'qrcode';
import domtoimage from "dom-to-image";

import './index.css';
import image from '../../assets/images/img-fullcard.png';
import axios from "axios";

const Share = (props) => {
    const [preload_image, setPreloadImage] = useState('');
    const [qr, setQr] = useState('');
    const [signup, setSignup] = useState('');

    useEffect(() => {
        const { uuid, token } = queryString.parse(props.location.search);

        const getRedirectionLinks = async() => {
            const config = await axios.request({
                method: 'GET',
                url: '/api/config/interface/getConfig',
            });
            let signup = config.data.data.crm_url + '/signup/index?hl=zh';


            setSignup(signup);
        }

        getRedirectionLinks();

        const getQrCode = async() => {
            const url = await QRCode.toDataURL(signup + '&utm_source=Wechat&utm_medium=SocialMedia&utm_campaign=SpringRedEnvelopes&utm_term=InviteRegister&utm_content=' + uuid, {color: {dark: '#000000', light: '#FFFFFF'}, margin: 1});
            setQr(url);
        }

        const getGeneratedImage = async() => {
            await axios.get('/api/v1/redpacket/get',
                {
                    params: {
                        uuid: uuid,
                        token: token
                    }
                }).then((response) => {
                    if (response.data.data.data.shareImage) {
                        setPreloadImage(response.data.data.data.shareImage);
                    }
            });
        }

        if (uuid) {
            getQrCode();
            getGeneratedImage()

            // const getRedPacket = getGeneratedImage();
            // console.log(getRedPacket);
            // if (getRedPacket && getRedPacket.data.data.data.shareImage) {
            //     setPreloadImage(getRedPacket.data.data.data.shareImage);
            // }
        }
    }, []);


    const getImage = async() => {
        let download_link = '';

        if (preload_image) {
            download_link = preload_image;
        } else {
            const d2image = await domtoimage.toPng(document.getElementById("homepage"), { quality: 0.5})

            console.log(d2image)
            console.log("Image Processing complete!")

            download_link = d2image;
        }

        const link = document.createElement('a');
        link.download = 'doo-prime-cny-share.png';
        link.href = download_link;
        link.click();
    }

    return (
        <div>
            <div id="homepage">
                <img id="background" src={preload_image ? preload_image : image} alt="QR Share"/>
                {
                    !preload_image &&
                    <div id="qr">
                        <img src={qr} />
                    </div>
                }
            </div>
            <button className="downloadbutton" onClick={getImage}>保存分享领红包</button>
        </div>
    );
}

export default Share;
