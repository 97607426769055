import React, {PureComponent} from "react";

class Text extends PureComponent {
    state = {
        visible: false,
    };

    render() {
        const { visible } = this.state;

        return (
            <div className="list-wrapper wow fadeInUp" onClick={() => this.setState({ visible: !visible })}>
                <div className="list wow fadeInUp">
                    <span>{this.props.title}</span>
                    <img src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit3-arrow.png" />
                </div>
                {
                    visible && (
                        <div className="text wow fadeInUp">{this.props.item}</div>
                    )
                }
            </div>
        );
    }
}

export default Text;
