import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import moment from "moment";
import postscribe from 'postscribe';

import Card from "./card";

const Element = (props) => {
    const { user_type, qr, get_account_details } = props;

    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    const [userType, setUserType] = useState(user_type);
    const [showRedeemButton, setShowRedeemButton] = useState(false);
    const [event, setEvent] = useState('');

    useEffect(() => {
        const checkEvent = async() => {
            await checkTimeLimit();
        }

        checkEvent();
        setUserType(user_type);
    }, [user_type]);

    setInterval(async() => {
        let timer = checkEventDate();
        setCountdown(timer);
        await checkTimeLimit();
    }, 1000);

    const daily_blessings_dates = [
        {
            start: '2021-02-13 01:00:00',
            end: '2021-02-13 23:59:59'
        },
        {
            start: '2021-02-14 00:00:00',
            end: '2021-02-14 23:59:59'
        },
        {
            start: '2021-02-15 00:00:00',
            end: '2021-02-15 23:59:59'
        },
        {
            start: '2021-02-16 00:00:00',
            end: '2021-02-16 23:59:59'
        },
        {
            start: '2021-02-17 00:00:00',
            end: '2021-02-17 23:59:59'
        },
        {
            start: '2021-02-18 00:00:00',
            end: '2021-02-18 23:59:59'
        }
    ];

    const redeemPackets = async (type) => {
        let data = {};
        let params = {};
        let updated_params = {};

        params = {
            params: {
                uuid: sessionStorage.getItem("uuid"),
                token: sessionStorage.getItem('token'),
                type: type,
            },
        };
        updated_params = {
            params: {
                uuid: sessionStorage.getItem("uuid"),
                type: type,
            },
        };

        data.type = type;
        const getRedPackets = await axios.get(
            "/api/v1/redpacket/getRedPackets",
            params
        );
        if (getRedPackets && (getRedPackets.data.data.code !== 0)) {
            openPopup("info", getRedPackets.data.data.code, type);
            return null;
        } else {
            data.redeemed_packets = getRedPackets.data.data.data.redPackets;
        }
        if (getRedPackets && (!getRedPackets.data.data.userAuthenticated)) {
            sessionStorage.clear();
        }

        const getNotReceivingRedPackets = await axios.get(
            "/api/v1/redpacket/getNotReceivingRedPackets",
            updated_params
        );
        if (getNotReceivingRedPackets && (getNotReceivingRedPackets.data.data.code !== 0)) {
            openPopup("info", getNotReceivingRedPackets.data.data.code, type);
            return null;
        } else {
            data.available_packets = getNotReceivingRedPackets.data.data.data.notReceivingRedPacketsAmount;
        }

        return data;
    };

    const checkEventDate = () => {
        let countdown = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        if (get_account_details) {
            const spring_festival = get_account_details.activityTime.timeLimitActivity.springFestivalActivities;
            const yuanxiao_festival = get_account_details.activityTime.timeLimitActivity.yuanxiaoActivities;

            const spring_festival_start = spring_festival.from;
            const spring_festival_end = spring_festival.to
            const yuanxiao_festival_start = yuanxiao_festival.from;
            const now = moment(get_account_details.activityTime.serverTime);

            if (now.isBefore(spring_festival_start)) {
                countdown = getEventCountdown(spring_festival_start);
            } else if (now.isBetween(spring_festival_end, yuanxiao_festival_start, undefined, '[)')) {
                countdown = getEventCountdown(yuanxiao_festival_start);
            }
        }

        return countdown;
    }

    const getEventCountdown = (date) => {
        const eventDate = moment(date, "YYYY-MM-DD HH:mm:ss");
        const startDate = moment().utcOffset(480).format("YYYY-MM-DD HH:mm:ss");

        const difference = moment.duration(eventDate.diff(startDate));

        return {
            days: difference.days(),
            hours: difference.hours(),
            minutes: difference.minutes(),
            seconds: difference.seconds()
        };
    }

    const checkTimeLimit = async() => {
        if (get_account_details) {
            const spring_festival = get_account_details.activityTime.timeLimitActivity.springFestivalActivities;
            const yuanxiao_festival = get_account_details.activityTime.timeLimitActivity.yuanxiaoActivities;

            const spring_festival_start = spring_festival.from;
            const spring_festival_end = spring_festival.to;
            const yuanxiao_festival_start = yuanxiao_festival.from;
            const yuanxiao_festival_end = yuanxiao_festival.to;

            const daily_blessings_start = '2021-02-13 01:00:00';
            const daily_blessings_end = '2021-02-18 23:59:59';

            const campaign_end = '2021-02-26 23:59:59';

            const now = moment(get_account_details.activityTime.serverTime);

            if (now.isAfter(campaign_end)) {
                setShowRedeemButton(false);
                openPopup("info", "campaign-end", "");
            }

            if (now.isBetween(daily_blessings_start, daily_blessings_end, undefined, '[)')) {
                daily_blessings_dates.map((date, index) => {
                    if (now.isBetween(date.start, date.end, undefined, '[)')) {
                        if (localStorage.getItem('daily') !== index.toString()) {
                            openPopup("daily", index, null);
                            localStorage.setItem('daily', index.toString());
                        }
                    }
                })
            }

            if (now.isBetween(spring_festival_start, spring_festival_end, undefined, '[)')) {
                setEvent("springFestivalActivities");
                setShowRedeemButton(true);
                if (!localStorage.getItem('spring_festival')) {
                    openPopup("info", "event", "springFestivalActivities");
                    localStorage.setItem('spring_festival', 'true');
                }
            } else if (now.isBetween(spring_festival_end, moment(spring_festival_end).add(1, 'hour'), undefined, '[)')) {
                setEvent("");
                setShowRedeemButton(false);
                if (!localStorage.getItem('post_spring_festival')) {
                    openPopup("info", "post-event", 4);
                    localStorage.setItem('post_spring_festival', 'true');
                }
            } else if (now.isBetween(yuanxiao_festival_start, yuanxiao_festival_end, undefined, '[)')) {
                setEvent("yuanxiaoActivities");
                setShowRedeemButton(true);
                if (!localStorage.getItem('yuanxiao_festival')) {
                    openPopup("info", "event", "yuanxiaoActivities");
                    localStorage.setItem('yuanxiao_festival', 'true');
                }
            } else if (now.isBetween(yuanxiao_festival_end, moment(yuanxiao_festival_end).add(30, 'minutes'), undefined, '[)')) {
                setEvent("");
                setShowRedeemButton(false);
                if (!localStorage.getItem('post_yuanxiao_festival')) {
                    openPopup("info", "post-event", 4);
                    localStorage.setItem('post_yuanxiao_festival', 'true');
                }
            } else {
                setShowRedeemButton(false);
            }
        }
    };

    const registrationLink = async () => {
        const config = await axios.request({
            method: 'GET',
            url: '/api/config/interface/getConfig',
        })

        let login = config.data.data.crm_url
        let signup = config.data.data.crm_url + '/signup/index?hl=zh'

        if (userType === 2) {
            window.location.href = signup;
        } else {
            window.location.href = login;
        }
    };

    const depositLink = async (activity) => {
        postscribe('#deposit-button', '<script type=\'text/javascript\'>\n' +
            '    (function(w,d,s,l,a){\n' +
            '        w._CommandName_=l;w[l]=w[l]||function(){(w[l].q=w[l].q||[]).push(arguments);\n' +
            '            w[l].track = function(){(w[l].q[w[l].q.length-1].t=[]).push(arguments)};return w[l]},w[l].a=a,w[l].l=1*new Date();\n' +
            '        var c = d.createElement(s);c.async=1;\n' +
            '        c.src=\'//fm.ipinyou.com/j/a.js\';\n' +
            '        var h = d.getElementsByTagName(s)[0];h.parentNode.insertBefore(c, h);\n' +
            '    })(window,document,\'script\',\'py\',\'fls8T..oGddCRSe8Hn7nZfPE4iDRP\');\n' +
            '    py(\'event\',\'statistics\',\'fls8T.cdZ.QNRdZXplg6_opc-TQ-24-0\');\n' +
            '</script>');

        const config = await axios.request({
            method: 'GET',
            url: '/api/config/interface/getConfig',
        })
        let login = config.data.data.crm_url
        let deposit = config.data.data.crm_url + '/fund/deposit'


        // if (userType === 1) {
        //     window.location.href = deposit;
        // } else if (userType === 0) {
        //     openPopup("info", "kyc", activity);
        //     document.documentElement.style.overflowY = "hidden";
        // } else {
        //     window.location.href = login;
        // }

        if (userType === 1) {
            window.location.href = deposit;
        } else {
            window.location.href = login;
        }
    };

    const redemptionLink = async (activity) => {
        // postscribe('.redeem-button', '<script type=\'text/javascript\'>\n' +
        //     '    (function(w,d,s,l,a){\n' +
        //     '        w._CommandName_=l;w[l]=w[l]||function(){(w[l].q=w[l].q||[]).push(arguments);\n' +
        //     '            w[l].track = function(){(w[l].q[w[l].q.length-1].t=[]).push(arguments)};return w[l]},w[l].a=a,w[l].l=1*new Date();\n' +
        //     '        var c = d.createElement(s);c.async=1;\n' +
        //     '        c.src=\'//fm.ipinyou.com/j/a.js\';\n' +
        //     '        var h = d.getElementsByTagName(s)[0];h.parentNode.insertBefore(c, h);\n' +
        //     '    })(window,document,\'script\',\'py\',\'fls8T..oGddCRSe8Hn7nZfPE4iDRP\');\n' +
        //     '    py(\'event\',\'statistics\',\'fls8T.NdZ.AZNUWZKVHVVlowWhee_PwP\');\n' +
        //     '</script>');

        const config = await axios.request({
            method: 'GET',
            url: '/api/config/interface/getConfig',
        })
        let login = config.data.data.crm_url

        // if (userType === 1) {
        //     const result = await redeemPackets(activity);
        //     if (result) {
        //         openPopup("open", result, activity);
        //     }
        //     document.documentElement.style.overflowY = "hidden";
        // } else if (userType === 0) {
        //     openPopup("info", "kyc", activity);
        //     document.documentElement.style.overflowY = "hidden";
        // } else {
        //     window.location.href = login;
        // }

        if (userType === 1) {
            const result = await redeemPackets(activity);
            if (result) {
                openPopup("open", result, activity);
            }
            document.documentElement.style.overflowY = "hidden";
        } else {
            window.location.href = login;
        }
    };

    const eventLink = async (event) => {
        // postscribe('.redeem-button', '<script type=\'text/javascript\'>\n' +
        //     '    (function(w,d,s,l,a){\n' +
        //     '        w._CommandName_=l;w[l]=w[l]||function(){(w[l].q=w[l].q||[]).push(arguments);\n' +
        //     '            w[l].track = function(){(w[l].q[w[l].q.length-1].t=[]).push(arguments)};return w[l]},w[l].a=a,w[l].l=1*new Date();\n' +
        //     '        var c = d.createElement(s);c.async=1;\n' +
        //     '        c.src=\'//fm.ipinyou.com/j/a.js\';\n' +
        //     '        var h = d.getElementsByTagName(s)[0];h.parentNode.insertBefore(c, h);\n' +
        //     '    })(window,document,\'script\',\'py\',\'fls8T..oGddCRSe8Hn7nZfPE4iDRP\');\n' +
        //     '    py(\'event\',\'statistics\',\'fls8T.NdZ.AZNUWZKVHVVlowWhee_PwP\');\n' +
        //     '</script>');

        openPopup("info", "event", event);
        document.documentElement.style.overflowY = "hidden";
    };

    const openPopup = (type, content, activity) => {
        props.onTrigger(type, content, activity);
    };

    const content = [
        {
            title: "享高达 US$ 888 交易红利",
            titleImage: (
                <React.Fragment>
                    <img
                        src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/text-benefit1-title@2x.png"
                        className="titleImg desktop"/>
                    <img
                        src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-text-benefit1-title@2x_new.png"
                        className="titleImg mobile"/>
                </React.Fragment>
            ),
            body: (
                <div>
                    <div className="content-wrapper content-title-img wow fadeInUp">
                        <div>
                            入金<font>US$1,000</font>
                        </div>
                        <div>
                            <span>+</span>
                        </div>
                        <div>
                            交易<font>1手</font>
                        </div>
                        <div>
                            <span>=</span>
                        </div>
                        <div>
                            领取
                            <span>
                                <img
                                    id="angpow"
                                    src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/angpow.png"
                                />
                                <font>1个</font>
                            </span>

                        </div>
                        {/*<div id="diamond">
                            <img
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit1-diamondframe.png"/>
                            <h4 id="wordings">
                                入金交易越多，
                                <br/>
                                红包越多！
                                <br/>
                                随时提现！
                            </h4>
                        </div>*/}
                    </div>
                    <p className="card-msg wow fadeInUp">
                        入金交易越多，红包越多！随时提现！
                    </p>
                    <div className="content-wrapper content-wish wow fadeInUp">
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-leftcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-leftcloud@2x.png"
                            />
                        </div>
                        <div>
                            <span className="wow fadeInUp">日进斗金，财福双收！</span>
                        </div>
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-rightcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-rightcloud@2x.png"
                            />
                        </div>
                    </div>
                    {userType === 1 && get_account_details && (
                        <div className="content-wrapper content-info wow fadeInUp">
                            <div className="title wow fadeInUp">
                                <span>活动期间</span>
                            </div>
                            <img
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit1-box@2x.png" className="wow fadeInUp"/>
                            <div className="count wow fadeInUp">
                                <p>
                                    您的入金量为 <span>US$ {get_account_details.totalAmount}</span>
                                </p>
                                <p>
                                    标准手数 <span>{get_account_details.tradingNumber}手</span>
                                </p>
                                <p>
                                    待领取{" "}
                                    <span>{get_account_details.depositActivityNotReceivingRedPacketsAmount}个红包</span>
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="content-wrapper content-button wow fadeInUp">
                        <div>
                            <button onClick={() => depositLink(1)} id="deposit-button">立即入金交易</button>
                            <button onClick={() => redemptionLink(1)} className="redeem-button">立即领取</button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "开户双重礼",
            titleImage: (<img
                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/text-benefit2-title@2x.png"
                className="titleImg wow fadeInUp"/>),
            body: (
                <div>
                    <div className="content-wrapper content-title wow fadeInUp">
                        <div>
                            <p>活动期间成功开户</p>并达到以上入金交易量目标，<p className="pmobile">额外领取迎新红包 1 个！</p>
                        </div>
                    </div>
                    <div className="content-wrapper content-wish wow fadeInUp">
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-leftcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-leftcloud@2x.png"
                            />
                        </div>
                        <div>
                            <span>交易得心应手，牛年喜事常有！</span>
                        </div>
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-rightcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-rightcloud@2x.png"
                            />
                        </div>
                    </div>
                    {userType === 1 && get_account_details && (
                        <div className="content-wrapper content-info wow fadeInUp">
                            <img
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit2-box@2x.png" className="wow fadeInUp"/>
                            <div className="count wow fadeInUp">
                                待領取:&nbsp;
                                <span>{get_account_details.newUserRedPacketsAmount}个红包</span>
                            </div>
                        </div>
                    )}
                    <div className="content-wrapper content-button wow fadeInUp">
                        <div>
                            {
                                !sessionStorage.getItem("uuid") &&
                                <button onClick={() => registrationLink()}>立即开户</button>
                            }
                            <button onClick={() => redemptionLink(2)} className="redeem-button">立即领取</button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "战友助力",
            titleImage: (<img
                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/text-benefit3-title@2x.png"
                className="titleImg wow fadeInUp"/>),
            body: (
                <div>
                    <div className="content-wrapper content-title wow fadeInUp">
                        <div>
                            分享您的专属福气卡<br className="mobile wow fadeInUp"/>并完成以下步骤， <br className="mobile"/>即可领取助力红包 1 个！
                        </div>
                    </div>
                    <div className="content-wrapper desktop wow fadeInUp" id="content-social">
                        <div>
                            <div className="line wow fadeInUp"><span className="subline"></span></div>
                            <table className="step wow fadeInUp">
                                <tr>
                                    <td>01</td>
                                    <td>02</td>
                                    <td>03</td>
                                    <td>04</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>手机扫描以下二维码， 下载您的专属福气卡</div>
                                    </td>
                                    <td>
                                        <div>手动分享福气卡到<p>朋友圈/ QQ群/ 好友等</p></div>
                                    </td>
                                    <td>
                                        <div>集齐<span className="friend">3位</span>战友扫码，<p>并成功注册开户</p></div>
                                    </td>
                                    <td>
                                        <div>您即可领取<p>助力红包1个！</p></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="image-single">
                                            {userType === 1 && qr && (
                                                <div className="qr-code">
                                                    <img src={props.qr}/>
                                                </div>
                                            )}
                                            {userType !== 1 && (
                                                <div className="full-width">
                                                    <img class="nonlogin-qrcode" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/SVG/icon-nonlogin-benefit3-qrcode.svg" />
                                                    <button onClick={() => registrationLink()}>
                                                        登录生成二维码
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="image-multiple">
                                            <img
                                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-wechat@2x.png"/>
                                            <img
                                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-QQ@2x.png"/>
                                            <img
                                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-weibo@2x.png"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="image-single">
                                            <img
                                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-phone@2x.png"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="image-single benefit-angpow">
                                            <img
                                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-angpow.png"/>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="mobile wow fadeInUp" id="content-social-carousel">
                        <Carousel showStatus={false} showThumbs={false}>
                            <div className="carousel-content">
                                <div>01</div>
                                <div>手机扫描以下二维码，<br/>下载您的专属福气卡</div>
                                {userType === 1 && qr && (
                                    <div className="qr-code">
                                        <img src={qr}/>
                                    </div>
                                )}
                                {userType !== 1 && (
                                    <div>
                                        <img class="nonlogin-qrcode" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/SVG/icon-nonlogin-benefit3-qrcode.svg"  />
                                        <button id="qr-code-btn" onClick={() => registrationLink()}>
                                            登录生成二维码
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="carousel-content">
                                <div>02</div>
                                <div>手动分享福气卡到<br/>朋友圈/ QQ群/ 好友等</div>
                                <div id="img-social">
                                    <img
                                        src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-wechat@2x.png"/>
                                    <img
                                        src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-QQ@2x.png"/>
                                    <img
                                        src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-weibo@2x.png"/>
                                </div>
                            </div>
                            <div className="carousel-content">
                                <div>03</div>
                                <div>集齐3位战友扫码，<br/>并成功注册开户</div>
                                <div>
                                    <img
                                        id="img-phone"
                                        src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-phone@2x.png"
                                        height={124}
                                    />
                                </div>
                            </div>
                            <div className="carousel-content">
                                <div>04</div>
                                <div className="mb24">您即可领取<br/>助力红包1个！</div>
                                <div className="benefit-angpow">
                                    <img
                                        id="img-angpow"
                                        src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit3-angpow.png"
                                        height={124}
                                    />
                                </div>
                            </div>
                        </Carousel>
                    </div>
                    <p className="oneChance wow fadeInUp">*每人限领一次</p>
                    <div className="content-wrapper content-wish wow fadeInUp">
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-leftcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-leftcloud@2x.png"
                            />
                        </div>
                        <div>
                            <span className="wow fadeInUp">与战友同沾福气，好运连连！</span>
                        </div>
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-rightcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-rightcloud@2x.png"
                            />
                        </div>
                    </div>
                    {userType === 1 && get_account_details && (
                        <div className="content-wrapper content-info wow fadeInUp">
                            <img
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit2-box.png"/>
                            <div className="count wow fadeInUp">
                                已助力战友:&nbsp;
                                <span>{get_account_details.inviteUserAmountTotal}位</span>
                            </div>
                        </div>
                    )}
                    <div className="content-wrapper content-button wow fadeInUp">
                        <div>
                            <button onClick={() => redemptionLink(3)} className="redeem-button">立即领取</button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "限定红包放送",
            titleImage: (<img
                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/text-benefit4-title@2x.png"
                className="titleImg wow fadeInUp"/>),
            body: (
                <div>
                    <div className="content-wrapper content-title wow fadeInUp">
                        <div>
                            与 Doo Prime 一起<p>欢喜度新春，共庆元宵节！</p>
                            于指定时间登陆此活动页，即可领限定红包 1 个！
                        </div>
                    </div>
                    <div className="content-wrapper content-wish wow fadeInUp">
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-leftcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-leftcloud@2x.png"
                            />
                        </div>
                        <div>
                            <span>欢喜贺岁，团团圆圆！</span>
                        </div>
                        <div>
                            <img
                                className="desktop wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit-rightcloud@2x_new.png"
                            />
                            <img
                                className="mobile wow fadeInUp"
                                src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit-rightcloud@2x.png"
                            />
                        </div>
                    </div>
                    <div className="content-wrapper content-info wow fadeInUp">
                        <img
                            src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit4-box@2x.png"/>
                        <div className="count wow fadeInUp">
                            倒数：
                            {   get_account_details && (
                                <>
                                    &nbsp;
                                    <span>{countdown.days}</span>天
                                    <span>{countdown.hours}</span>时
                                    <span>{countdown.minutes}</span>分
                                    <span>{countdown.seconds}</span>秒
                                </>)
                            }
                        </div>
                    </div>
                    {
                        (user_type === 1) && showRedeemButton &&
                        <div className="content-wrapper content-button wow fadeInUp">
                            <div>
                                <button onClick={() => eventLink(event)} className="redeem-button">立即领取</button>
                            </div>
                        </div>
                    }
                    <div className="wow fadeInUp" id="message">
                        密切留意
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="element">
            {content.map((card, i) => {
                return (
                    <Card
                        title={card.title}
                        titleImage={card.titleImage}
                        content={card.body}
                        index={i + 1}
                        large={i === 2}
                    />
                );
            })}
        </div>
    );
};

export default Element;
