import React from 'react';
import "./index.css";

const DailyBlessings = (props) => {
    const { popup_content } = props;

    const daily_blessings = [
        {
            date: '02.13',
            day: '二',
            title: '投资全球',
            content: '提供 10,000+ 个交易品种'
        },
        {
            date: '02.14',
            day: '三',
            title: ' 安心交易',
            content: '多国监管，多重安全防护'
        },
        {
            date: '02.15',
            day: '四',
            title: '选择专业券商',
            content: '全球18,000+专业客户的选择'
        },
        {
            date: '02.16',
            day: '五',
            title: '极速成交',
            content: '50 毫秒内执行 99.5% 交易订单'
        },
        {
            date: '02.17',
            day: '六',
            title: '极低成本交易',
            content: '点差低至 0.1pips、无额外手续费'
        },
        {
            date: '02.18',
            day: '七',
            title: '慈善捐赠',
            content: '提供便利交易，不忘回馈社会'
        }
    ];

    return (
        <div className="modal modal_blessing">
            <div className="modal_content">
                <div className="modal_wrapper">
                        <span className="close wow fadeInUp blessing_close" onClick={props.closePopup}>
                            &#10005;
                        </span>
                    <img src="https://cdn.dootech.io/doo-prime-cny/Pop-up%20Window/PNG/img-popup13-bg.png" className="wow fadeInUp" />
                    <div className="divbox wow fadeInUp">
                        <div className="divboxleft wow fadeInUp">
                            <p className="pbox">{daily_blessings[popup_content].date}</p>
                        </div>
                        <div className="divboxmain wow fadeInUp">
                            <p className="blessingheader">正月 <br/>
                                初{daily_blessings[popup_content].day}</p>
                        </div>
                        <div className="divboxright wow fadeInUp">
                            <p className="pbox">大吉</p>
                        </div>
                    </div>
                    <div className="content_wrapper wow fadeInUp">
                        <p className="blessingbody1 wow fadeInUp"><span className="span1">宜</span> &nbsp; | &nbsp; {daily_blessings[popup_content].title} </p>
                        <label>
                            <p className="blessingbody2 wow fadeInUp"><strong>Doo Prime 德璞资本</strong><br/>{daily_blessings[popup_content].content}</p>
                            <p className="blessingbody3 wow fadeInUp"> 祝您牛年交易大吉, <br/> 天天领红包! </p>
                            <div>
                            </div>
                        </label>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DailyBlessings;
