import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <div className="header-wrapper">
                <div className="header">
                    <img className="desktop" src="https://cdn.dootech.io/doo-prime-cny/Share/img-banner_new6.png" alt="Banner"/>
                    <img className="mobile" src="https://cdn.dootech.io/doo-prime-cny/Share/m-img-banner@2x.png" alt="Banner"/>
                    <div className="header-desc wow fadeInUp">
                        <figure className="maintitle desktop wow fadeInUp"><img src="https://cdn.dootech.io/doo-prime-cny/Share/text-banner-maintitle.svg" alt="Banner"/></figure>
                        <figure className="maintitle mobile wow fadeInUp"><img src="https://cdn.dootech.io/doo-prime-cny/Share/m-text-banner-maintitle@2x.png" alt="Banner"/></figure>
                        <figure className="subtitle desktop shine wow fadeInUp"><img src="https://cdn.dootech.io/doo-prime-cny/Share/text-banner-subtitle@2x_new.png" alt="Banner"/></figure>
                        <figure className="subtitle mobile wow fadeInUp"><img src="https://cdn.dootech.io/doo-prime-cny/Share/redpacket888.png" alt="Banner"/></figure>
                        <figure className="decor wow fadeInUp"><img src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-banner-element1.png" /></figure>

                        <p className="duration desktop wow fadeInUp">
                            活动时间:<br/>
                            <span>2021年2月8日至26日</span>
                        </p>
                    </div>
                    <p className="duration mobile wow fadeInUp">
                        活动时间:<br/>
                        <span>2021年2月8日至26日</span>
                    </p>
                </div>
            </div>
        );
    }
}

export default Header;
