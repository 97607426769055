import React, { Component } from 'react';

import './index.css';
import axios from "axios";
import postscribe from 'postscribe';

class Navbar extends Component {
    state = {
        login: '',
        signup: ''
    }

    onUserLogout = () => {
        this.props.onUserLogout();
    }

    async componentDidMount() {
        const config = await axios.request({
            method: 'GET',
            url: '/api/config/interface/getConfig',
        });
        this.setState({ login: config.data.data.crm_url, signup: config.data.data.crm_url + '/signup/index?hl=zh' });
    }

    fireTracking = () => {
        postscribe('#login-button', '<script type=\'text/javascript\'>\n' +
            '    (function(w,d,s,l,a){\n' +
            '        w._CommandName_=l;w[l]=w[l]||function(){(w[l].q=w[l].q||[]).push(arguments);\n' +
            '            w[l].track = function(){(w[l].q[w[l].q.length-1].t=[]).push(arguments)};return w[l]},w[l].a=a,w[l].l=1*new Date();\n' +
            '        var c = d.createElement(s);c.async=1;\n' +
            '        c.src=\'//fm.ipinyou.com/j/a.js\';\n' +
            '        var h = d.getElementsByTagName(s)[0];h.parentNode.insertBefore(c, h);\n' +
            '    })(window,document,\'script\',\'py\',\'fls8T..oGddCRSe8Hn7nZfPE4iDRP\');\n' +
            '    py(\'event\',\'statistics\',\'fls8T.UdZ.LbK28AM8U9Kg2vTcb7-6i0\');\n' +
            '</script>');
    }

    render() {
        return (
            <div className="navbar wow fadeInUp">
                <div className="login">
                    <div className="desktop wow fadeInUp">
                        {
                            sessionStorage.getItem("uuid") ? (
                                <div className="top-wrapper">
                                    <span className="greeting">{sessionStorage.getItem("uuid") + ", 您好！"}</span>|<span className="logout" onClick={this.onUserLogout}>退出登录</span>
                                </div>

                            ) : (
                                <a href={this.state.login}>
                                    <img src="https://cdn.dootech.io/prime-website2/images/icon-common-account.png" />
                                    <span>登录</span>
                                </a>
                            )
                        }

                    </div>
                    <div className="mobile wow fadeInUp">
                        <a href={"https://www.dooprime.net/"} target={"_blank"}>
                            <img src="https://cdn.dootech.io/doo-prime-cny/Mobile/Logo/m-logo-DPheader.svg" alt="Logo"/>
                        </a>
                        <div>
                            {
                                sessionStorage.getItem("uuid") ? (
                                    <span className="wow fadeInUp">{sessionStorage.getItem("uuid") + ", 您好！"}</span>
                                ) : (
                                    <>
                                        <a href={this.state.login}>
                                            <img src="https://cdn.dootech.io/prime-website2/images/icon-common-account.png" />
                                        </a>
                                    </>
                                )
                            }
                            {
                                sessionStorage.getItem("uuid") ? (
                                    <span className="logout" onClick={this.onUserLogout}>退出登录</span>
                                ) : (
                                    <button onClick={this.fireTracking} id='login-button'>
                                        <a href={this.state.signup}>立即开户</a>
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="logo wow fadeInUp">
                    <a href={"https://www.dooprime.net/"} target={"_blank"}>
                        <img src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/logo-DPheader.svg" alt="Logo" className="wow fadeInUp"/>
                    </a>
                    {
                        !sessionStorage.getItem("uuid") &&
                        <button className="wow fadeInUp" onClick={this.fireTracking} id='login-button'>
                            <a href={this.state.signup}>立即开户</a>
                        </button>
                    }
                </div>
            </div>
        )
    }
}

export default Navbar;
