import React, { Component } from 'react';

import Menu from './menu';
import Text from './text';
import axios from "axios";
import postscribe from 'postscribe';

const content = [
    {
        title: "市场",
        items: [
            {
                title: "",
                items: [
                    {
                        name: "外汇",
                        link: "https://www.dooprime.net/sc/trade/exchange"
                    },
                    {
                        name: "贵金属",
                        link: "https://www.dooprime.net/sc/trade/metal"
                    },
                    {
                        name: "能源",
                        link: ""
                    },
                    {
                        name: "指数",
                        link: "https://www.dooprime.net/sc/trade/cfd"
                    },
                    {
                        name: "证劵",
                        link: "https://www.dooprime.net/sc/trade/stock"
                    },
                    {
                        name: "期货",
                        link: "https://www.dooprime.net/sc/trade/futures"
                    }
                ]
            }
        ]
    },
    {
        title: "工具",
        items: [
            {
                title: "交易软件",
                items: [
                    {
                        name: "MetaTrader 4",
                        link: "https://www.dooprime.net/sc/platform/pc"
                    },
                    {
                        name: "MetaTrader 5",
                        link: "https://www.dooprime.net/sc/platform/pc"
                    },
                    {
                        name: "Doo Prime InTrade",
                        link: "https://www.dooprime.net/sc/platform/admin"
                    },
                    {
                        name: "TradingView",
                        link: ""
                    },
                    {
                        name: "FIX API",
                        link: ""
                    }
                ]
            },
            {
                title: "社区化交易",
                items: [
                    {
                        name: "Outrade",
                        link: "https://www.dooprime.net/sc/community/landing"
                    },
                    {
                        name: "MyfxBook",
                        link: ""
                    },
                    {
                        name: "FOLLOWME",
                        link: ""
                    }
                ]
            },
            {
                title: "深度分析",
                items: [
                    {
                        name: "Trading Central",
                        link: ""
                    },
                    {
                        name: "财经日历",
                        link: "https://www.dooprime.net/sc/trade/calendar"
                    }
                ]
            },
            {
                title: "Tools",
                items: [
                    {
                        name: "VPS 交易专用服务器 ",
                        link: "https://www.dooprime.net/sc/platform/vps"
                    },
                    {
                        name: "即时报价历史",
                        link: ""
                    }
                ]
            }
        ]
    },
    {
        title: "交易",
        items: [
            {
                title: "",
                items: [
                    {
                        name: "报价模型",
                        link: ""
                    },
                    {
                        name: "尖端科技",
                        link: ""
                    },
                    {
                        name: "账户对比",
                        link: ""
                    },
                    {
                        name: "资金存取",
                        link: "https://www.dooprime.net/sc/company/financial"
                    }
                ]
            },
            {
                title: "交易须知",
                items: [
                    {
                        name: "杠杆倍数",
                        link: ""
                    },
                    {
                        name: "隔夜利息",
                        link: "https://www.dooprime.net/sc/trade/interest"
                    },
                    {
                        name: "交易日历",
                        link: ""
                    },
                    {
                        name: "合约细则",
                        link: ""
                    }
                ]
            }
        ]
    },
    {
        title: "推广合作",
        items: [
            {
                title: "会员计划",
                items: [
                    {
                        name: "理想家积分商城",
                        link: "https://pointsmall.dooprimeasiasc.com/home"
                    }
                ]
            },
            {
                title: "合作伙伴",
                items: [
                    {
                        name: "介绍经纪商",
                        link: ""
                    },
                    {
                        name: "白标经纪商",
                        link: "https://www.dooprime.net/sc/partner/white"
                    },
                    {
                        name: "区域办公室",
                        link: "https://www.dooprime.net/sc/partner/cooperation"
                    }
                ]
            }
        ]
    },
    {
        title: "关于 Doo Prime",
        items: [
            {
                title: "",
                items: [
                    {
                        name: "公司介绍",
                        link: "https://www.dooprime.net/sc/company/profile"
                    },
                    {
                        name: "企业社会责任",
                        link: ""
                    },
                    {
                        name: "媒体中心",
                        link: ""
                    },
                    {
                        name: "合规披露",
                        link: ""
                    },
                    {
                        name: "联络我们",
                        link: "https://www.dooprime.net/sc/company/contact"
                    },
                    {
                        name: "招贤纳士",
                        link: "https://www.dooprime.net/sc/company/contact"
                    }
                ]
            },
            {
                title: "媒体中心",
                items: [
                    {
                        name: "首页",
                        link: ""
                    },
                    {
                        name: "重要通告",
                        link: ""
                    },
                    {
                        name: "公司动态",
                        link: ""
                    },
                    {
                        name: "活动资讯",
                        link: ""
                    },
                    {
                        name: "行业动态",
                        link: ""
                    },
                    {
                        name: "实战策略",
                        link: ""
                    }
                ]
            }
        ]
    }
]

const list = [
    {
        title: "请仔细阅读以下合规披露",
        text: "本网站由 Doo Prime Vanuatu Limited 拥有和运营。\n" +
            "\n" +
            "该网站可在全球范围内访问，并不特定于任何实体。这主要是为了信息集中展示和对比的便利，你的实际权利和义务会基于你所选择的实体从监管所获得的授权和监管所决定。\n" +
            "\n" +
            "您必须至少年满18岁，然后才能访问我们的网站产品和服务。 通过访问我们的网站，您确认您符合了年龄的要求。\n" +
            "\n" +
            "有些当地法律法规禁止或限制您访问，下载，分发，传播，共享或以其他方式使用本网站上发布的任何或所有文档和信息的权利。"
    },
    {
        title: "高风险交易产品风险披露",
        text: "由于基础金融工具的价值和价格会有剧烈变动，股票，证券，期货，差价合约和其他金融产品交易涉及高风险，可能会在短时间内发生超过您的初始投资的大额亏损。\n" +
            "\n" +
            "在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险。 如果您不了解此处说明的风险，则应寻求独立专业的意见。\n" +
            "\n" +
            "过去的投资表现并不代表其未来的表现。"
    },
    {
        title: "品牌和实体公告",
        text: "Doo Financial, Doo Clearing, Doo Prime, Doo Tech, Doo Consulting and Peter & Elish 是Doo Holding Group 所拥有的商用名称、注册商标和知识产权。\n" +
            "\n" +
            "我们有各种受监管和许可的实体提供相关的金融服务。 您可以与我们的任何实体建立业务关系。 所选择的实体意味着您的交易账户，交易活动和资金只由这特定的监管机构进行监管。\n" +
            "\n" +
            "我们不向某些辖区的个人和公司提供服务，包括但不限于阿富汗，奥地利，阿塞拜疆，比利时，波斯尼亚和黑塞哥维那，保加利亚，缅甸，加拿大，刚果，克罗地亚，古巴，塞浦路斯，捷克共和国，丹麦，爱沙尼亚，埃塞俄比亚，芬兰，法国，德国，希腊，圭亞那，海地，香港，匈牙利，爱尔兰，印度尼西亚，伊朗，伊拉克，以色列，意大利，日本，拉脱维亚，黎巴嫩，立陶宛，卢森堡，马来西亚，马里，马耳他，荷兰，朝鲜，波兰，葡萄牙，罗马尼亚，新加坡，斯洛伐克，斯洛文尼亚，斯里兰卡， 索马里，西班牙，苏丹，瑞典，特立尼达和多巴哥，突尼斯，委内瑞拉，美国。 我们网站上的信息和服务不适用于在此类信息和服务的发布违反各自当地法规的国家或司法管辖区。 来自上述地区的访客应在使用我们的服务之前，确认您对我们的服务进行投资的决定是否符合您居住国家/地区的法规。 我们保留随时更改，修改或终止我们的产品和服务的权利。"
    },
    {
        title: "客户须知",
        text: "此处显示的任何交易符号仅用于说明目的，不构成我们的任何建议。 本网站上提供的任何评论，陈述，数据，信息，材料或第三方材料（“材料”）仅供参考。 该材料仅被认为是市场传播，不包含，也不应被解释为包含任何交易的投资建议和/或投资建议。 尽管我们已尽一切合理的努力确保信息的准确性和完整性，但我们对材料不做任何陈述和保证，如果所提供信息的任何不准确和不完整，我们也不对任何损失负责，包括但不限于利润损失，直接或间接损失或损害赔偿。 未经我们的同意，您只能将该材料用于个人用途，不得复制，复制，重新分发和/或许可该材料。\n" +
            "\n" +
            "我们使用我们网站上的cookies来根据您的喜好自定义我们网站上显示的信息和体验。 通过访问本网站，您承认您已经阅读并同意上述详细信息，并同意我们使用cookies。\n" +
            "\n" +
            "我们完全遵守司法管辖区中所有适用的法律和法规。 请确保您的投资符合要求是您的责任，您将承担投资和交易的所有后果。"
    }
]

class Footer extends Component {
    state = {
        visible: false,
        hover: false,
        signup: ''
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const config = await axios.request({
            method: 'GET',
            url: '/api/config/interface/getConfig',
        });
        this.setState({signup: config.data.data.crm_url + '/signup/index?hl=zh'}) ;

    }

    mouseOver = () => {
        this.setState({ hover: true });
    }
    mouseOut() {
        this.setState({ hover: false });
    }

    fireTracking = () => {
        postscribe('#demo_acc', '<script type=\'text/javascript\'>\n' +
            '    (function(w,d,s,l,a){\n' +
            '        w._CommandName_=l;w[l]=w[l]||function(){(w[l].q=w[l].q||[]).push(arguments);\n' +
            '            w[l].track = function(){(w[l].q[w[l].q.length-1].t=[]).push(arguments)};return w[l]},w[l].a=a,w[l].l=1*new Date();\n' +
            '        var c = d.createElement(s);c.async=1;\n' +
            '        c.src=\'//fm.ipinyou.com/j/a.js\';\n' +
            '        var h = d.getElementsByTagName(s)[0];h.parentNode.insertBefore(c, h);\n' +
            '    })(window,document,\'script\',\'py\',\'fls8T..oGddCRSe8Hn7nZfPE4iDRP\');\n' +
            '    py(\'event\',\'statistics\',\'fls8T.bdZ.nFPz7sq-aVWedFeuOOeWW_\');\n' +
            '</script>');
        window.location.href = "https://www.dooprime.net/sc/simulation";
    }

    render() {
        const { visible, hover } = this.state;

        return (
            <div className="footer">
                <div className="count">
                    <img src="https://cdn.dootech.io/prime-website2/images/arrow_big.png" className="wow fadeInUp" />
                    <div className="count-content wow fadeInUp">
                        <p id="number">18<span>,</span>000+</p>
                        <p id="desc">环球客户</p>
                        <p id="number">10<span>,</span>000+</p>
                        <p id="desc">交易产品</p>
                    </div>
                    <button id="open_acc" className="wow fadeInUp"><a href={this.state.signup}>立即开户</a></button>
                    <button id="demo_acc" className="wow fadeInUp" onClick={this.fireTracking}>
                        模拟账户
                    </button>
                </div>
                <div className="links">
                    <div className="desktop">
                        <div id="policy-wrapper-bg">
                            <div id="policy-wrapper" className="wow fadeInUp">
                                <div id="policy" className="wow fadeInUp">
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/ClientAgreement_DooPrimeVanuatuLimited_19January2021.pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>客户协议</span>
                                    </a>
                                    <span>|</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/AMLAndCTFPolicy-Doo-Prime-Vanuatu-Limited-16-%20December-2020.pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>AML & CTF 政策</span>
                                    </a>
                                    <span>|</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Execution_Policy_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>执行政策</span>
                                    </a>
                                    <span>|</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Refund_Policy_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>退款政策</span>
                                    </a>
                                    <span>|</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/RiskDisclosureAndAcknowledgement_DooPrimeVanuatuLimited_19January2021.pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>风险披露声明</span>
                                    </a>
                                    <span>|</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Privacy_Policy_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>个人隐私条例声明</span>
                                    </a>
                                    <span>|</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Website_Terms_and_Conditions_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>条款及细则</span>
                                    </a>
                                </div>
                                <div id="sitemap" className="wow fadeInUp">
                                    <span>网站地图</span>
                                </div>
                            </div>
                        </div>
                        <div id="navigation">
                            <div className="wow fadeInUp">
                                <img src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/logo-DPfooter.png" alt="Logo" className="wow fadeInUp" />
                                <div id="social" className="wow fadeInUp">
                                    <a href={"https://www.facebook.com/DooPrime/"} target={"_blank"}><img src="https://cdn.dootech.io/prime-website2/images/icon-share-Facebook.svg" alt="Facebook" className="wow fadeInUp" /></a>
                                    <a href={"https://www.instagram.com/dooprime/"} target={"_blank"} className="wow fadeInUp"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-INS.svg" alt="Instagram" /></a>
                                    <a href={"https://www.linkedin.com/company/dooprime"} target={"_blank"} className="wow fadeInUp"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-Linkedin.svg" alt="LinkedIn" /></a>
                                    <a href={"https://twitter.com/DooPrime"} target={"_blank"} className="wow fadeInUp"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-twitter.svg" alt="Twitter" /></a>
                                    <a onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)} className="wow fadeInUp">
                                        <img src="https://cdn.dootech.io/prime-website2/images/icon-share-WeChat.svg" alt="WeChat"/>
                                    </a>
                                </div>
                                {
                                    hover && (
                                        <div className="wechat-qr wow fadeInUp">
                                            <img src={"https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/wechat_code.png"}/>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                content.map((menu, i) => {
                                    return (
                                        <div>
                                            <span className="wow fadeInUp">{menu.title}</span><br/>
                                            {
                                                menu.items && (menu.items.map((item, i) => {
                                                    return (
                                                        <div>
                                                            <span className="list-title wow fadeInUp">{item.title}</span>
                                                            <ul>
                                                                {
                                                                    item.items.map((sub, i) => {
                                                                        return (
                                                                            <li>
                                                                                <a href={sub.link} target={"_blank"} className="wow fadeInUp">{sub.name}</a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    )
                                                }))
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className="menu">
                                <div className="menu-title wow fadeInUp">
                                    <a href={"https://www.dooprime.com/sc/faq"} target={"_blank"}><span>帮助中心</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile">
                        <div id="policy-wrapper wow fadeInUp">
                            <div id="policy">
                                <div>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/ClientAgreement_DooPrimeVanuatuLimited_19January2021.pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>客户协议</span>
                                    </a>
                                    <span>&emsp;|&emsp;</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/AMLAndCTFPolicy-Doo-Prime-Vanuatu-Limited-16-%20December-2020.pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>AML & CTF 政策</span>
                                    </a>
                                    <span>&emsp;|&emsp;</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Execution_Policy_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>执行政策</span>
                                    </a>
                                    <span>&emsp;|&emsp;</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Refund_Policy_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>退款政策</span>
                                    </a>
                                    <span>&emsp;|&emsp;</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/RiskDisclosureAndAcknowledgement_DooPrimeVanuatuLimited_19January2021.pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>风险披露声明</span>
                                    </a>
                                    <span>&emsp;|&emsp;</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Privacy_Policy_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>个人隐私条例声明</span>
                                    </a>
                                    <span>&emsp;|&emsp;</span>
                                    <a href={"http://cdn.dootech.io/prime-website2/docs/Website_Terms_and_Conditions_(Doo%20Prime).pdf"} target={"_blank"} className="wow fadeInUp">
                                        <span>条款及细则</span>
                                    </a>
                                    <span>&emsp;|&emsp;</span>
                                    <span className="wow fadeInUp">网站地图</span>
                                </div>
                            </div>
                        </div>
                        <div id="navigation">
                            <img id="logo" className="wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/logo-DPfooter.png" alt="Logo" />
                            {
                                content.map((menu, i) => {
                                    return (<Menu title={menu.title} items={menu.items} />)
                                })
                            }
                            <div className="menu wow fadeInUp" onClick={() => { window.open("https://www.dooprime.com/sc/faq") }}>
                                <div className="menu-title">
                                    <span>帮助中心</span>
                                </div>
                            </div>
                            <div className="social wow fadeInUp">
                                <a href={"https://www.facebook.com/DooPrime/"} target={"_blank"} className="wow fadeInUp"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-Facebook.svg" alt="Facebook" /></a>
                                <a href={"https://www.instagram.com/dooprime/"} target={"_blank"} className="wow fadeInUp"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-INS.svg" alt="Instagram" /></a>
                                <a href={"https://www.linkedin.com/company/dooprime"} target={"_blank"} className="wow fadeInUp"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-Linkedin.svg" alt="LinkedIn" /></a>
                                <a href={"https://twitter.com/DooPrime"} target={"_blank"} className="wow fadeInUp"><img src="https://cdn.dootech.io/prime-website2/images/icon-share-twitter.svg" alt="Twitter" /></a>
                                <a onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)} className="wow fadeInUp">
                                    <img src="https://cdn.dootech.io/prime-website2/images/icon-share-WeChat.svg" alt="WeChat"/>
                                </a>
                            </div>
                            <div className="social wechat-qr wow fadeInUp">
                                {
                                    hover && (
                                        <img src={"https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/wechat_code.png"}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div id="help-center">
                        <h4 className="wow fadeInUp">合规披露</h4>
                        <span id="country" className="wow fadeInUp">{this.props.country_code && "我们检测到您可能来自我们不提供服务的司法管辖区： " + this.props.country_code + "。"}</span>
                        {
                            list.map((text, i) => {
                                return (<Text title={text.title} item={text.text}/>)
                            })
                        }
                        <div className="list active wow fadeInUp" onClick={() => this.setState({ visible: !visible })}>
                            <span>Doo 实体公告</span>
                            <img src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit3-arrow.png" />
                        </div>
                        {
                            visible && (
                                <div>
                                    <div className="text wow fadeInUp">
                                        <div className="wow fadeInUp">
                                            <span>Doo Technology Limited</span><br/>
                                            <br/>
                                            是一家注册地址位于 Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong 的有限责任公司，公司注册编号为2535212。<br/>
                                            <br/>
                                            Doo Technology Limited 是一家服务于金融行业的软件技术供应商。<br/>
                                            <br/>
                                            <a href={"https://doo.tech"}>doo.tech</a>
                                        </div>
                                        <div className="wow fadeInUp">
                                            <span>Doo Consulting Limited</span><br/>
                                            <br/>
                                            是一家注册地址位于 Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong 的有限责任公司，公司注册编号为2601567。<br/>
                                            <br/>
                                            Doo Consulting Limited 是一家专业服务提供者，由香港特别行政区公司注册处授予信托及公司服务提供者（TCSP）牌照，牌照编号为TC006709。<br/>
                                            <br/>
                                            <a href={"https://www.doo.hk"}>doo.hk</a>
                                        </div>
                                    </div>
                                    <div className="text wow fadeInUp">
                                        <div className="wow fadeInUp">
                                            <span>Elish & Elish Inc</span><br/>
                                            <br/>
                                            是一家注册地址位于 5750 Baltic Blvd, Plano, Texas, TX 75212 United States 的公司。<br/>
                                            <br/>
                                            Elish & Elish Inc 是一家受美国证监会（US SEC）和 FINRA 监管的经纪商，监管编号为 CRD: 24409 / SEC: 8-41551。<br/>
                                            <br/>
                                            PETER ELISH INVESTMENTS SECURITIES 是Elish & Elish Inc 授权的商业名称。
                                            <br/>
                                            <a href={"#"}>doofinancial.com</a><br/>
                                            <a href={"#"}>elish.com</a>
                                        </div>
                                        <div className="wow fadeInUp">
                                            <span>Doo Clearing Limited</span><br/>
                                            <br/>
                                            是一家注册地址位于 208 Uxbridge Road, Shepherds Bush, London, England, W12 7JD 的有限责任公司， 公司注册编号为10684079。<br/>
                                            <br/>
                                            Doo Clearing Limited 是一家期货及外汇经纪商和流动性提供商，由英国金融行为监管局（UK FCA）授权和监管，监管编号为833414。<br/>
                                            <br/>
                                            <a href={"https://www.dooclearing.co.uk/"}>www.dooclearing.co.uk</a>
                                        </div>
                                    </div>
                                    <div className="text wow fadeInUp">
                                        <div className="wow fadeInUp">
                                            <span>Doo Prime Vanuatu Limited</span><br/>
                                            <br/>
                                            是一家注册地址位于 Govant Building, Po Box 1276, Port Vila, Vanuatu 的有限责任公司, 商业登记号码为700238。<br/>
                                            <br/>
                                            Doo Prime Vanuatu Limited 是一家金融衍生品交易经纪商，由瓦努阿图金融服务委员会（VFSC）授权和监管，监管编号为700238。<br/>
                                            <br/>
                                            <a href={"https://www.dooprime.com"}>www.dooprime.com</a>
                                        </div>
                                        <div className="wow fadeInUp">
                                            <span>Doo Prime Mauritius Limited</span><br/>
                                            <br/>
                                            是一家注册地址位于 Level 6, GFin Tower, 42 Hotel Street, Cybercity, Ebene 72201, Mauritius 的有限责任公司, 商业登记号码为 C119023907。<br/>
                                            <br/>
                                            Doo Prime Mauritius Limited 是一家投资交易商，由毛里求斯金融服务委员会（FSC）授权和监管，监管编号为 C119023907。<br/>
                                            <br/>
                                            <a href={"https://www.dooprime.com"}>www.dooprime.mu</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="copyright">
                    <span>本网站由 Doo Prime Vanuatu Limited 拥有和运营，版权©2020，版权所有。</span>
                </div>
                <div className="copyright icp">
                <span>晋ICP备2020013591号</span>
                </div>
            </div>
        );
    }
}

export default Footer;
