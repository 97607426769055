import React, { Component } from 'react';

import Description from "./description";
import Element from './element';
import Rules from "./rules";

import './index.css';

class Content extends Component {
    constructor(props) {
        super(props);
    }

    onTrigger = (type, content, activity) => {
        this.props.onTrigger(type, content, activity);
    }

    render() {
        const { user_type, qr, get_account_details, get_red_packet_details, show_redeem_button } = this.props;

        return (
            <div className="content">
                <Description
                    user_type={user_type}
                    get_account_details={get_account_details}
                    get_red_packet_details={get_red_packet_details}
                />
                <Element
                    user_type={user_type}
                    get_account_details={get_account_details}
                    show_redeem_button={show_redeem_button}
                    qr={qr}
                    onTrigger={this.onTrigger}
                />
                <Rules />
            </div>
        );
    }
}

export default Content;
