import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import Mask from '../components/mask';
import Layout from '../components/layout';
import Share from '../components/share';

const Routes = () => {
    const [mask, setMask] = useState(false);

    useEffect(() => {
        layerMask();
    }, [])

    function is_weixin(){
        const ua = navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
            return true;
        }
    }
    const layerMask = () => {
        if (is_weixin()) {
            setMask(true);
        }
    }

    return (
        <Switch>
            <Route path="/redPacket/share/:uuid?/:token?" component={mask ? Mask :Share} />
            <Route path="/share/:uuid?/:token?" component={mask ? Mask :Share} />
            <Route path="/" component={mask ? Mask : Layout} />
            <Route path="/redPacket" component={mask ? Mask : Layout} />
        </Switch>
    );
}

export default Routes;
