import React, { useState, useEffect } from 'react';
import moment from "moment";

const Description = (props) => {
    const { user_type, get_account_details, get_red_packet_details } = props;
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        displayTotal();
    }, []);

    const total_packets = (props) => {
        return Math.round(props.hasReceivingRedPacketsAmountTotal);
    }

    const displayTotal = () => {
        const display_date = "2021-02-09 00:00:00";

        const now = moment();

        if (now.isBefore(display_date)) {
            setDisplay(false);
        } else {
            setDisplay(true);
        }
    }

    return (
        <div className="description wow fadeInUp">
            <div className="total wow fadeInUp">
                {
                    display &&
                        <>
                            <img className="desktop" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-bg-left-element2@2x.png" />
                            <div>
                                <img src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/text-intro1@2x.png" className="totalPackets wow fadeInUp" /><br/>
                                {
                                    get_account_details && String(total_packets(get_account_details)).split("").map((char, i) => {
                                        return <div><span key="{char}">{char}</span></div>;
                                    })
                                }
                                <br/>
                                <img src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/text-intro2@2x.png" className="totalPackets wow fadeInUp"/>
                            </div>
                            <img className="desktop wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-bg-right-element3@2x.png" />
                        </>
                }
            </div>
            {
                (user_type === 1) && (get_red_packet_details) && (
                    <div className="info wow fadeInUp">
                        <div className="title wow fadeInUp"><span>活动期间</span></div>
                        <img
                            src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-benefit2-box@2x.png" className="wow fadeInUp"/>
                        <div className="count wow fadeInUp">
                            <p>您已领取红包&nbsp;<span>{get_red_packet_details.hasReceivingRedPacketsAmount} 个</span></p>
                            <p>共&nbsp;<span>US$ {get_red_packet_details.hasReceivingRedPacketsBonus}</span></p>
                        </div>
                    </div>
                )
            }
            <div className="intro wow fadeInUp">
                <div className="images wow fadeInUp">
                    <img className="mobile wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-img-intro-leftcoins.png" />
                    <img id="fan" className="wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/img-intro-fan.png" />
                    <img className="mobile wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-img-intro-rightcoins.png" />
                </div>
                <div>
                    <img className="desktop wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/img-intro-leftcoins.png" />
                    <p className="wow fadeInUp">
                        <strong>Doo Prime 德璞资本</strong>作为您的专属交易券商,<br />
                        于新春期间, 为新老客户献上多重福利。<br />
                        完成以下步骤即可领取开运红包, 奖金随时提现。<br />
                        祝您吉星高照, 牛气冲天！
                    </p>
                    <img className="desktop wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/img-intro-rightcoins.png" />
                </div>
                <img id="divider" className="wow fadeInUp" src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/icon-banner-element1.png" />
            </div>
        </div>
    );
}

export default Description;
