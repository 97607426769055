import React, { Component } from 'react';

class Card extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title_height: 'auto',
            content_height: 'auto'
        };
    }

    componentDidMount() {
        const title_height = this.header.clientHeight + 100;
        const content_height = this.container.clientHeight + 260;
        this.setState({ title_height, content_height });
    }

    render() {
        return (
            <div className="card wow fadeInUp">
                <div className="title wow fadeInUp" ref={ (header) => { this.header = header }}>
                    <div>{this.props.titleImage}</div>
                    <img src="https://cdn.dootech.io/doo-prime-cny/Login%20Version/SVG/icon-benefit-ribbon.svg"  height={this.state.title_height} />
                    <div className="label">福利 {this.props.index}</div>
                    <div className="line">
                        <div className="middle-line"> </div>
                    </div>
                </div>
                <div className="card-content wow fadeInUp" ref={ (container) => { this.container = container }}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}

export default Card;
