import React, { Component } from 'react';
import axios from "axios";

class Rules extends Component {
    state = {
        desktop_height: 'auto',
        mobile_height: 'auto',
        deposit: ''
    }

    handleResize = () => this.setState({
        desktop_height: document.getElementById('text').clientHeight + 250,
        mobile_height: document.getElementById('text').clientHeight + 500 });

    async componentDidMount() {
        this.handleResize();
        window.addEventListener('load resize', this.handleResize);

        const config = await axios.request({
            method: 'GET',
            url: '/api/config/interface/getConfig',
        })
        this.setState({ deposit: config.data.data.crm_url + '/fund/deposit' })
    }

    componentWillUnmount() {
        window.removeEventListener('load resize', this.handleResize);
    }

    render() {
        return (
            <div className="rules">
                <img className="mobile" src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-img-rules@2x.png" height={this.state.mobile_height} />
                <div className="text" id="text">
                    <div className="textWidth wow fadeInUp">
                        <h2 className="wow fadeInUp">活动规则</h2>
                        {/*<p className="wow fadeInUp">*/}
                        {/*    <span>参加客户：</span>*/}
                        {/*    <span>仅限中国客户参加</span>*/}
                        {/*</p>*/}
                        <p className="wow fadeInUp">
                            <span>活动时间：</span>
                            <span>北京时间2021年2月8日00:00:00 - <br className="mobile" />2月26日 23:59:59</span>
                        </p>
                        <p className="wow fadeInUp">
                            <span>入金渠道：</span>
                            <span>用户中心的入金通道</span>
                        </p>
                        <div className="logo wow fadeInUp">
                            <a href={this.state.deposit}><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-cny/Login%20Version/PNG/icon-rules-unionpay@2x.png" alt="Union Pay" /></a>
                            <a href={this.state.deposit}><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-cny/Login%20Version/PNG/icon-rules-wiretransfer@2x.png" alt="Wire Transfer" /></a>
                            <a href={this.state.deposit}><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-cny/Login%20Version/PNG/icon-rules-banktransfer@2x.png" alt="Bank Transfer" /></a>
                            <a href={this.state.deposit}><img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-cny/Login%20Version/PNG/icon-rules-paypal@2x.png" alt="Pay Pal" /></a>
                        </div>
                        <table className="table">
                        <tbody>
                            <tr className="wow fadeInUp">
                                <td><span><img src="https://cdn.dootech.io/doo-prime-cny/Share/m-text-rules-01@2x.png" alt="01" /></span></td>
                                <td>
                                    <span>
                                        入金及交易量以个人为准, 不足 US$1,000的余额会累积计算, 交易账号之间的转账不获计算在内。 交易以平仓时间及客户的STP或ECN账号的交易量为准，其他账号类型不获计算在内。 达到入金交易目标后，待领红包数量会在10分钟内自动更新。
                                    </span>
                                </td>
                            </tr>
                            <tr className="wow fadeInUp">
                                <td><span><img src="https://cdn.dootech.io/doo-prime-cny/Share/m-text-rules-02@2x.png" alt="02" /></span></td>
                                <td>
                                    <span>
                                        红包金额随机抽取; 红包打开后数量将自动扣除；奖金会自动入
                                        到客户钱包，并可随时提现；红包累积计算, 直到活动结束为止。
                                        如超过活动时期而未领取的红包将会自动作废。
                                    </span>
                                </td>
                            </tr>
                            <tr className="wow fadeInUp">
                                <td><span><img src="https://cdn.dootech.io/doo-prime-cny/Share/m-text-rules-03@2x.png" alt="03" /></span></td>
                                <td>
                                    <span>
                                        新开户注册时必须通过实名认证方为有效。
                                    </span>
                                </td>
                            </tr>
                            <tr className="wow fadeInUp">
                                <td><span><img src="https://cdn.dootech.io/doo-prime-cny/Share/m-text-rules-04@2x.png" alt="04" /></span></td>
                                <td>
                                    <span>
                                        为保障公平性，我们将严格监测作弊、对冲交易及违规交易行为。一经发现，Doo Prime 德璞资本有权扣除奖金并取消其参加活动的资格。
                                    </span>
                                </td>
                            </tr>
                            <tr className="wow fadeInUp">
                                <td><span><img src="https://cdn.dootech.io/doo-prime-cny/Share/m-text-rules-05@2x.png" alt="05" /></span></td>
                                <td>
                                    <span>
                                        客户在参加活动的同时，默认已经阅读理解并同意条款细则。
                                    </span>
                                </td>
                            </tr>
                            <tr className="wow fadeInUp">
                                <td><span><img src="https://cdn.dootech.io/doo-prime-cny/Share/m-text-rules-06@2x.png" alt="06" /></span></td>
                                <td>
                                    <span>
                                        本活动最终解释权归 Doo Prime 德璞资本所有。
                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Rules;
