import React, { useState, useEffect } from 'react';
import "./index.css";
import axios from "axios";
import postscribe from 'postscribe';

const Info = (props) => {
    const [login, setLogin] = useState('');
    const [deposit, setDeposit] = useState('');
    const [availablePackets, setAvailablePackets] = useState(0);

    useEffect(() => {
        const checkAvailability = async() => {
            if (props.user_type === 1) {
                const getNotReceivingRedPackets = await axios.get(
                    "/api/v1/redpacket/getNotReceivingRedPackets",
                    {
                        params: {
                            uuid: sessionStorage.getItem("uuid"),
                            type: 4,
                            timeLimitActivityType: props.popup_activity,
                        }
                    }
                );

                if (getNotReceivingRedPackets && (getNotReceivingRedPackets.data.data.code === 0)) {
                    setAvailablePackets(getNotReceivingRedPackets.data.data.data.notReceivingRedPacketsAmount);
                }
            } else {
                setAvailablePackets(1);
            }
        }

        const getRedirectionLinks = async() => {
            const config = await axios.request({
                method: 'GET',
                url: '/api/config/interface/getConfig',
            })

            let login = config.data.data.crm_url;
            let deposit = config.data.data.crm_url + '/fund/deposit';

            setLogin(login);
            setDeposit(deposit);
        }

        getRedirectionLinks();
        checkAvailability();
    }, []);

    const getErrorMessage = (code) => {
        let message = "";

        if (typeof code === "number") {
            code = code.toString();
        }

        switch(code) {
            case "1001":
                message = "暂无红包领取";         // user does not fulfill redeem requirement
                break;
            case "5000":
                message = "用户不存在";              // invalid user
                break;
            case "5001":
                message = "未完成KYC认证";           // kyc user
                break;
            case "5002":
                message = "暂无红包领取";     // no pending red packets
                break;
            case "5003":
                message = "暂无红包领取";            //  no red packets for redemption
                break;
            case "5004":
                message = "活动关闭";               // activity closed
                break;
            case "5005":
                message = "活动暂未开启";             // activity not started
                break;
            case "5006":
                message = "活动已经结束";         // activity ended
                break;
            case "5007":
                message = "用户角色不符合";        // incorrect user
                break;
            case "5008":
                message = "账户异常，请联系客服";        // incorrect user
                break;
            default :
                message = "请求繁忙";           // system busy
                break;
        }

        return message;
    }

    const redeemPackets = async () => {
        let data = {};

        const params = {
            params: {
                uuid: sessionStorage.getItem("uuid"),
                token: sessionStorage.getItem('token'),
                type: 4,
                timeLimitActivityType: props.popup_activity,
            },
        };

        const updated_params = {
            params: {
                uuid: sessionStorage.getItem("uuid"),
                type: 4,
                timeLimitActivityType: props.popup_activity,
            },
        };

        data.type = 4;
        const getRedPackets = await axios.get(
            "/api/v1/redpacket/getRedPackets",
            params
        );
        if (getRedPackets && getRedPackets.data.data.code !== 0) {
            openPopup("info", getRedPackets.data.data.code, 4);
            return null;
        } else {
            data.redeemed_packets = getRedPackets.data.data.data.redPackets;
        }
        if (getRedPackets && (!getRedPackets.data.data.userAuthenticated)) {
            sessionStorage.clear();
        }

        const getNotReceivingRedPackets = await axios.get(
            "/api/v1/redpacket/getNotReceivingRedPackets",
            updated_params
        );
        if (getNotReceivingRedPackets && getNotReceivingRedPackets.data.data.code !== 0) {
            openPopup("info", getNotReceivingRedPackets.data.data.code, 4);
            return null;
        } else {
            data.available_packets = getNotReceivingRedPackets.data.data.data.notReceivingRedPacketsAmount;
        }

        return data;
    };

    const redirectLink = async() => {
        // if (props.user_type === 1) {
        //     const result = await redeemPackets();
        //     if (result) {
        //         openPopup("open", result, 4);
        //     }
        // } else if (props.user_type === 0) {
        //     openPopup("info", "kyc", 4);
        // } else {
        //     window.location.href = login;
        // }

        // postscribe('.popupbutton', '<script type=\'text/javascript\'>\n' +
        //     '    (function(w,d,s,l,a){\n' +
        //     '        w._CommandName_=l;w[l]=w[l]||function(){(w[l].q=w[l].q||[]).push(arguments);\n' +
        //     '            w[l].track = function(){(w[l].q[w[l].q.length-1].t=[]).push(arguments)};return w[l]},w[l].a=a,w[l].l=1*new Date();\n' +
        //     '        var c = d.createElement(s);c.async=1;\n' +
        //     '        c.src=\'//fm.ipinyou.com/j/a.js\';\n' +
        //     '        var h = d.getElementsByTagName(s)[0];h.parentNode.insertBefore(c, h);\n' +
        //     '    })(window,document,\'script\',\'py\',\'fls8T..oGddCRSe8Hn7nZfPE4iDRP\');\n' +
        //     '    py(\'event\',\'statistics\',\'fls8T.NdZ.AZNUWZKVHVVlowWhee_PwP\');\n' +
        //     '</script>');

        if (props.user_type === 1) {
            const result = await redeemPackets();
            if (result) {
                openPopup("open", result, 4);
            }
        } else {
            window.location.href = login;
        }
    }

    const openPopup = (type, content, activity) => {
        props.onTrigger(type, content, activity);
    };

    return (
        <div className="modal modal_info">
            <div className="modal_content">
                <div className="modal_wrapper">
                    {
                        (props.popup_content === "post-event") &&
                            <p className="first">手慢了, 限定红包放送已结束。</p>
                    }
                    {
                        (props.popup_content !== "campaign-end") &&
                        <span className="close" onClick={props.closePopup}>
                            &#10005;
                        </span>
                    }
                    <img src="https://cdn.dootech.io/doo-prime-cny/Pop-up%20Window/PNG/img-popup5-bg.png" />
                    {
                        (props.popup_content === "kyc") &&
                        <div>
                            <div className="content_wrapper">
                                <label className="wow fadeInUp">
                                    <p className="model_content_body3"> 请先完成实名认证 </p>
                                </label>
                                <button className="popupbutton wow fadeInUp">
                                    <a href={login}>立即认证</a>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        (props.popup_content === "event") &&
                        <div>
                            <div className="content_wrapper">
                                <p className="model_content_head wow fadeInUp">新春快乐！</p>
                                <label>
                                    <p className="model_content_body open-con wow fadeInUp">
                                        感谢您与 Doo Prime 一路相伴！
                                        <br/>
                                        您即将获得限定红包 {availablePackets} 个
                                    </p>
                                </label>
                                <p className="angpow-balance wow fadeInUp">
                                    *每人限领一次
                                </p>
                                {
                                    (availablePackets > 0) && <button className="popupbutton wow fadeInUp" onClick={() => redirectLink()}>立即领取</button>
                                }
                                {
                                    (availablePackets === 0) && <button className="popupbutton wow fadeInUp" onClick={props.closePopup}>立即领取</button>
                                }
                            </div>
                        </div>
                    }
                    {
                        (props.popup_content === "post-event") &&
                        <div>
                            <div className="content_wrapper">
                                <p className="model_content_head wow fadeInUp">福利不止于此！</p>
                                <label>
                                    <p className="model_content_body open-con wow fadeInUp">
                                        每入金US$ 1,000 及 交易 1 手,
                                        <br/>
                                        即可领取红包 1 个。
                                    </p>
                                </label>
                                <p className="model_content_body2 open-con wow fadeInUp">
                                    入金多多，交易多多，好运多多！
                                </p>
                                <p className="model_content_body3 wow fadeInUp">Doo Prime 祝您交易大吉，财源滚滚！</p>
                                <button className="popupbutton wow fadeInUp">
                                    <a href={deposit}>立即入金交易</a>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        (props.popup_content === "insufficient") &&
                        <div>
                            <div className="content_wrapper">
                                <p className="model_content_head wow fadeInUp">无法打开</p>
                                <label>
                                    <p className="model_content_body open-con wow fadeInUp">
                                        可领取红包为 0 个
                                        <br/>
                                        每入金US$ 1,000 及 交易 1 手,
                                        <br/>
                                        即可领取红包 1 个。
                                    </p>
                                    <p className="model_content_body wow fadeInUp">
                                        入金多多，交易多多，好运多多！
                                    </p>
                                </label>
                                <button className="popupbutton wow fadeInUp">
                                    <a href={deposit}>立即入金交易</a>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        (props.popup_content === "campaign-end") &&
                        <div className="campaign-end">
                            <div className="content_wrapper">
                                <p className="model_content_head wow fadeInUp">尊敬的客户，您好!</p>
                                <label>
                                    <p className="model_content_body content-white wow fadeInUp">
                                        <span>金牛开运夺头彩</span> 新春活动已经结束，
                                        <br/>
                                        感谢各大客户的支持！
                                    </p>
                                </label>
                                <p className="model_content_body wow fadeInUp">
                                    尚有更多精彩活动及最新交易信息，
                                    <br/>
                                    请登录官网了解更多。
                                </p>
                                <button className="popupbutton button-end wow fadeInUp">
                                    <a href="https://www.dooprime.net/sc">前往官网</a>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        (props.popup_content !== 'kyc') && (props.popup_content !== "event") && (props.popup_content !== "post-event") && (props.popup_content !== 'insufficient') && (props.popup_content !== 'campaign-end') &&
                        <div>
                            <div className="content_wrapper">
                                {
                                    (getErrorMessage(props.popup_content) === "请求繁忙") &&
                                    <div>
                                        <p className="model_content_head wow fadeInUp"> 新春快乐 ! </p>
                                        <label>
                                            <p className="model_content_body3 wow fadeInUp">
                                                金牛正忙，请稍后再来
                                            </p>
                                        </label>
                                    </div>
                                }
                                {
                                    (getErrorMessage(props.popup_content) !== "请求繁忙") &&
                                    <div>
                                        <p className="model_content_head wow fadeInUp">{ getErrorMessage(props.popup_content) }</p>
                                        <label>
                                            <p className="model_content_body3 wow fadeInUp"> </p>
                                        </label>
                                    </div>

                                }
                                <button className="popupbutton wow fadeInUp" onClick={props.closePopup}>
                                    稍后再来
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Info;
