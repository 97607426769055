import React, { PureComponent } from 'react';

class Menu extends PureComponent {
    state = {
        visible: false,
    };

    render() {
        const { visible } = this.state;

        return (
            <div className="menu wow fadeInUp" onClick={() => this.setState({ visible: !visible })}>
                <div className="menu-title wow fadeInUp">
                    <span>{this.props.title}</span>
                    <img src="https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit3-arrow.png" />
                </div>
                {
                    visible && (
                        this.props.items && (this.props.items.map((item, i) => {
                            return (
                                <div className="submenu-title wow fadeInUp">
                                    <span className="wow fadeInUp">{item.title}</span>
                                    {
                                        item.items.map((sub, index) => {
                                            return (
                                                <div className="submenu wow fadeInUp">
                                                    <a href={sub.link} target={"_blank"}>{sub.name}</a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }))
                    )
                }
            </div>
        );
    }
}

export default Menu;
