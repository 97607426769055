import React from "react";
import "./index.css";

import Info from "./info";
import Open from "./open";
import DailyBlessings from "./blessings";

const PopUp = (props) => {
    const { user_type, popup_type, popup_content, popup_activity, get_account_details } = props;

    const onTrigger = (type, content, activity) => {
        props.onTrigger(type, content, activity);
    }

    return (
        <div>
            {   popup_type === "info" && (
                <Info
                    user_type={user_type}
                    get_account_details={get_account_details}
                    popup_content={popup_content}
                    popup_activity={popup_activity}
                    closePopup={props.closePopup}
                    onTrigger={onTrigger}
                />)
            }
            {   popup_type === "open" && (
                <Open
                    uuid={sessionStorage.getItem("uuid")}
                    popup_content={popup_content}
                    get_account_details={get_account_details}
                    closePopup={props.closePopup}
                    onTrigger={onTrigger}
                />)
            }
            {   popup_type === "daily" && (
                <DailyBlessings
                    closePopup={props.closePopup}
                    popup_content={popup_content}
                />)
            }
        </div>
    );
};

export default PopUp;
